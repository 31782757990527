import React, { useState, useEffect } from "react";

function Footer() {
  const [footerText, setFooterText] = useState("");

  useEffect(() => {
    async function fetchFooterData() {
      const data = await getConfigData();
      setFooterText(data.FOOTER);
    }

    fetchFooterData();
  }, []);

  async function getConfigData() {
    const response = await fetch("/configuration.json");
    const data = await response.json();
    return data;
  }

  return (
    <footer className="w-full h-10 max-w-[90%] lg:max-w-2/5 xl:max-w-1/2 text-black text-center pb-2 mx-auto bottom-0 mt-0 mb-0">
      {footerText}
    </footer>
  );
}

export default Footer;
