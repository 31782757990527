import React, { useState } from "react";
import LeftNav from "../components/LeftNav";
import ChatContainer from "../components/ChatContainer";


function Home() {
  return (
       <div className="flex w-screen h-screen relative">
      <LeftNav />
      <ChatContainer />
    </div>
  );
}

export default Home;
