import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Page/Home";
import Login from "./Page/Login";
import ProtectedRoute from "./hooks/ProtectedRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
    <ToastContainer />
    <div className="overflow-hidden">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/guest-chat" element={<Home />} /> 
        <Route path="/" element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  </BrowserRouter>
  );
}

export default App;
